const toggleButtons = document.querySelectorAll('[data-js-header-toggle]');

if (toggleButtons && toggleButtons.length > 0) {
    toggleButtons.forEach((element) => {
        element.addEventListener('click', (event) => {
            const target = event.currentTarget as HTMLElement;

            target?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        });
    });
}

export {};

import { onDomReady, querySelectorAll } from '../utils/dom';
import visibilityDetector from '../utils/visibilityDetector';
import { processDomElementDataLayerEvents } from './tracking';

onDomReady(() => {
    const dataAttribute = 'data-js-dl-on-visible';
    const domElements = querySelectorAll(`[${dataAttribute}]`);

    domElements.forEach((el) => {
        visibilityDetector(el, (stopDetector) => {
            processDomElementDataLayerEvents(el);

            stopDetector();
        });
    });
});

export {};

import 'common/js/window';
import 'common/js/parts/lazy-images';
// @ts-ignore
import tracking from 'common/js/parts/tracking';
// @ts-ignore
import modal from 'common/js/modal/modal';
import 'common/js/parts/lazy-js';
import 'common/js/parts/active-on-scroll';
import 'common/js/parts/register-service-worker';
import 'common/js/parts/data-layer-on-visibility-events';
import './header-navigation';
import 'common/js/parts/cookie-policy';

tracking.init();
modal.init();

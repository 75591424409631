function visibilityDetector(
    el: HTMLElement,
    onVisible: (stopDetector: () => void) => void,
) {
    if (!el) {
        return null;
    }

    if ('IntersectionObserver' in window) {
        const visibilityObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const stopDetector = () => {
                        visibilityObserver.unobserve(el);
                    };

                    onVisible(stopDetector);
                }
            });
        });

        visibilityObserver.observe(el);
    } else {
        onVisible(() => {});
    }

    return true;
}

export default visibilityDetector;

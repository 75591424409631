if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((req) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }).catch((err) => {
        });
}

export {};
